import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { isEmpty, last } from 'lodash';
import { Subject } from 'rxjs';
import { filter, skip, take, takeUntil, tap } from 'rxjs/operators';
import { AppState } from '../../../../../store/reducers';
import { PRODUCT_MODEL_NAME } from '../../../../segments/models/segment';
import {
  SEGMENT_PROPERTY_NAMES,
  SegmentProperty,
} from '../../../../segments/models/segment-property';
import { getSegmentPropertiesByModel } from '../../../../segments/store/selectors/segment-properties.selector';

@Component({
  selector: 'app-model-array-value',
  templateUrl: './model-array-value.component.html',
  styleUrls: ['./model-array-value.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModelArrayValueComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ModelArrayValueComponent),
      multi: true,
    },
  ],
})
export class ModelArrayValueComponent
  implements OnInit, ControlValueAccessor, Validator, OnDestroy
{
  @Input() submitted = false;
  @Input() modelName = PRODUCT_MODEL_NAME;
  propertyNames = SEGMENT_PROPERTY_NAMES;

  get property(): SegmentProperty {
    return this._property;
  }

  @Input() set property(value: SegmentProperty) {
    this._property = value;
    this.store
      .pipe(select(getSegmentPropertiesByModel(this.modelName)), take(1))
      .subscribe((data: SegmentProperty[]) => {
        this.updateValidation();
        this.arrayProperties = data
          .filter((it) => it.parent === value.id && it.is_readable)
          .map((it) => ({ ...it, fieldName: this.getPropertyFieldName(it) }));
        this.arrayProperties.forEach((prop) => {
          this.emptyObject[prop.fieldName] = null;
        });
        this.isObject = data.filter((it) => it.parent === value.id).length > 0;
      });
  }

  private _property: SegmentProperty;

  form = new FormArray([]);
  newElementForm: FormGroup;
  subscriptions = new Subject();
  wrappedActionInProcess = false;
  skipChanges = false;
  isObject;
  arrayProperties: any[];
  emptyObject = {};
  hasAdded = false;

  onChange = (_) => {};
  onTouched = () => {};
  onValidationChange = () => {};

  constructor(private store: Store<AppState>, private fb: FormBuilder) {
    this.form.valueChanges
      .pipe(
        tap(() => this.updateValidation()),
        takeUntil(this.subscriptions),
        skip(1),
        filter(() => this.form.valid),
        filter((data) => data.filter((it) => !it).length === 0),
        filter(() => !this.skipChanges)
      )
      .subscribe((data) => {
        this.onChange(data);
      });
  }

  updateValidation() {
    setTimeout(() => {
      this.onValidationChange();
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.subscriptions)).subscribe(fn);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const validationErrors = this.form.controls.reduce(
      (errors: any, control) => {
        const controlErrors = control.errors;
        if (controlErrors) {
          errors = { ...controlErrors };
        }
        return errors;
      },
      { ...this.form.errors }
    );

    return isEmpty(validationErrors) ? null : validationErrors;
  }

  writeValue(obj: any[]): void {
    if (!obj) {
      obj = [];
    }

    this.wrapActionWithoutChanges(() => {
      while (this.form.length) {
        this.form.removeAt(0);
      }
      if (obj && obj.length) {
        obj.forEach((it) => {
          this.form.push(this.fb.group(this.emptyObject));
        });
        this.form.patchValue(obj);
      }
      this.newElementForm = this.fb.group(this.emptyObject);
    });
  }

  wrapActionWithoutChanges(action: Function) {
    if (this.wrappedActionInProcess) {
      action();
      return;
    }
    this.wrappedActionInProcess = true;
    this.skipChanges = true;

    action();
    setTimeout(() => {
      this.skipChanges = false;
      this.wrappedActionInProcess = false;
      this.updateValidation();
    }, 0);
  }

  add($event) {
    $event.preventDefault();
    if (this.newElementForm) {
      this.form.push(this.fb.group(this.newElementForm.value));
    }
    this.newElementForm = this.fb.group(this.emptyObject);

    this.form.push(this.fb.group({}));
    this.form.removeAt(this.form.length - 1);
  }

  remove(index) {
    this.form.removeAt(index);
  }

  trackByFn(index) {
    return index;
  }

  getPropertyFieldName(property: SegmentProperty) {
    return last(property.path.split('.'));
  }
}
